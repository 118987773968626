<template>
  <div>
    <b-card>
      <div class="row">
        <div class="col-md-4">
          <div class="d-flex flex-row">
            <label for="" class="label-font">Status :</label>
            <button @click="changeStatus(0)" class="btn">
              <span class="active-success"></span>
              <span :class="query.status === 0 ? 'has-border' : ''"
                >Active
                <b-badge variant="success">{{ active_count }}</b-badge></span
              >
            </button>
            <button @click="changeStatus(1)" class="btn p-0">
              <span class="active-danger"></span>
              <span :class="query.status === 1 ? 'has-border' : ''"
                >Inactive
                <b-badge variant="danger">{{ inactive_count }}</b-badge></span
              >
            </button>
          </div>
        </div>
        <div
          class="col-12 col-md-8 d-flex align-items-center justify-content-end"
        >
          <a
            href="https://grypas.inflack.xyz/grypas-api/bulk/Customer_Data_Sample.xlsx"
            class="btn mt-1 mt-lg-0 add-btn d-block mr-1"
            v-if="$can('import', 'Client')"
          >
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Download Sample </span>
              <span>
                <img
                  src="@/assets/images/icons/download-1.png"
                  alt="add icon"
                  height="20"
                  width="20"
                />
              </span>
            </div>
          </a>
          <router-link to="/import-customer" v-if="$can('import', 'Client')">
            <b-button class="btn mt-1 mt-lg-0 add-btn d-block mr-1">
              <div class="d-flex flex-row align-items-center">
                <span class="pr-1"> Import Clients </span>
                <span>
                  <img
                    src="@/assets/images/icons/upload-1.png"
                    alt="add icon"
                    height="20"
                    width="20"
                  />
                </span>
              </div>
            </b-button>
          </router-link>
          <b-button
            class="btn mt-1 mt-lg-0 add-btn d-block"
            v-if="$can('create', 'Client')"
          >
            <router-link to="/customer/create">
              <div class="d-flex flex-row align-items-center">
                <span class="pr-1"> Add Client </span>
                <span>
                  <img
                    src="@/assets/images/icons/add-icon.png"
                    alt="add icon"
                    height="20"
                    width="20"
                  />
                </span>
              </div>
            </router-link>
          </b-button>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-12">
          <div class="row">
            <div
              class="col-md-3"
              v-if="logedinUser.roles[0].name === 'superadmin'"
            >
              <label for="" class="label-font">Subsidiary Company:</label>
              <b-form-group>
                <v-select
                  v-model="query.company"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companies"
                  label="name"
                  placeholder="Select Subsidiary Company"
                  @input="getCustomers"
                />
              </b-form-group>
            </div>
            <div class="col-md-3">
              <label for="" class="label-font">Location:</label>
              <b-form-group class="rounded border border-primary">
                <v-select
                  @input="getCustomers"
                  v-model="query.location"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cities"
                  placeholder="Select Location"
                />
              </b-form-group>
            </div>
            <div class="col-md-3">
              <label for="" class="label-font">Industry :</label>
              <b-form-group class="rounded border border-primary">
                <v-select
                  @input="getCustomers"
                  v-model="query.selectedIndustry"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="industries"
                  label="name"
                  placeholder="Select Industry"
                />
              </b-form-group>
            </div>
            <div class="col-md-3">
              <label for="" class="label-font">Search:</label>
              <b-form-group class="rounded border border-primary">
                <b-form-input
                  v-debounce:300="getCustomers"
                  v-model="query.name"
                  type="text"
                  placeholder="Search"
                  class="search-input bg-light"
                />
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <b-card>
      <div>
        <b-table
          :items="customers"
          :fields="parentFields"
          show-empty
          striped
          hover
          class="bg-white cursor-icon text-left"
        >
          <template #cell(SL)="row">
            <div
              :class="`p-1 ${
                row.item.user_status === 'active'
                  ? 'active-success'
                  : 'active-danger'
              }`"
            >
              {{ row.index + from }}
            </div>
          </template>
          <template #cell(id)="row">
            <router-link
              v-if="$can('view', 'Client')"
              :to="`/customer/view/${row.item.id}`"
              class="text-primary"
              >{{ row.item.id }}</router-link
            >
            <span v-else>{{ row.item.id }}</span>
          </template>
          <template #cell(name)="row">
            <router-link
              v-if="$can('view', 'Client')"
              :to="`/customer/view/${row.item.id}`"
              class="text-primary"
              >{{ row.item.name }}</router-link
            >
            <span v-else>{{ row.item.name }}</span>
          </template>
          <template #cell(address)="row">
            {{ row.item.customer.address }}
          </template>
          <template #cell(location)="row">
            {{ row.item.customer.city }}
          </template>
          <template #cell(phone)="row">
            {{ row.item.phone }}
          </template>
          <template #cell(industry)="row">
            {{ getIndustry(row.item.customer.industry_id) }}
          </template>
          <template #cell(email)="row">
            {{ row.item.email }}
          </template>
          <template #cell(action)="row">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                v-if="logedinUser.roles[0].name === 'superadmin'"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <div class="mx-1">
                    <b-link
                      v-if="$can('update', 'Client')"
                      :to="`/customer/edit/${row.item.id}`"
                      class="btn btn-info btn-sm"
                    >
                      Edit
                    </b-link>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item>
                  <b-button
                    v-if="row.item.status == '0' && $can('suspend', 'Client')"
                    @click.prevent="suspendAlert(row.item.id)"
                    class="btn btn-warning btn-sm mx-1"
                  >
                    Suspend
                  </b-button>
                  <b-button
                    v-if="
                      row.item.status == '1' &&
                      $can('withdrawal suspension', 'Client')
                    "
                    @click.prevent="activeUser(row.item.id)"
                    class="btn btn-success btn-sm mx-1"
                  >
                    Withdrawal Suspension
                  </b-button>
                </b-dropdown-item>
                <b-dropdown-item>
                  <b-button
                    v-if="$can('suspend', 'Client')"
                    @click.prevent="deleteUser(row.item.id)"
                    class="btn btn-danger btn-sm mx-1"
                  >
                    Delete
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
              <div class="text-center" v-else>
                <b-link
                  v-if="$can('view', 'Client')"
                  :to="`/customer/view/${row.item.id}`"
                  class="btn btn-info btn-sm"
                >
                  View
                </b-link>
              </div>
            </span>
          </template>
        </b-table>
        <b-pagination
          v-if="total > query.per_page"
          class="p-1 d-flex justify-content-end bg-white"
          v-model="query.page"
          :total-rows="total"
          :per-page="query.per_page"
          aria-controls="my-table"
          @change="getCustomers($event)"
          pills
          size="lg"
          page-class="info"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { toast } from "@/utils/toast";
import {
  BAvatar,
  BBadge,
  BButton,
  BCalendar,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  BTooltip,
} from "bootstrap-vue";

import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BFormGroup,
    BFormCheckbox,
    BCard,
    BRow,
    BDropdownItem,
    BPagination,
    BContainer,
    BCalendar,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BTooltip,
    BDropdownItemButton,
  },

  data() {
    return {
      homeLink: false,
      total: 0,
      from: 0,
      companies: [],
      customers: [],
      cities: [],
      industries: [],
      logedinUser: JSON.parse(getLogedInUser()),
      query: {
        name: "",
        location: "",
        industry: null,
        selectedIndustry: [],
        status: 0,
        keyword: "",
        page: 1,
        per_page: 20,
      },
      parentFields: [
        {
          key: "SL",
          label: "SL",
          sortable: true,
        },
        {
          key: "id",
          label: "id",
          sortable: true,
        },
        {
          key: "name",
          label: "name",
          sortable: true,
        },
        {
          key: "address",
          label: "Suburb",
        },
        {
          key: "location",
          label: "State",
        },
        {
          key: "phone",
          label: "phone",
        },
        {
          key: "industry",
          label: "industry",
        },
        {
          key: "email",
          label: "email",
        },
        {
          key: "action",
          label: "action",
        },
      ],
      inactive_count: "",
      active_count: "",
    };
  },
  created() {
    this.getCompanies();
    this.getCustomers();
    this.getIndustries();
  },
  methods: {
    changeStatus(status) {
      this.query.status = status;
      this.getCustomers();
    },
    getCompanies() {
      let query = {
        per_page: 1000,
      };
      this.$store
        .dispatch("company/getCompaniesForDropdown")
        .then(({ data }) => {
          this.companies = data;
        });
    },
    getCustomers(e) {
      if (e && typeof e === "number") {
        this.query.page = e;
      } else {
        this.query.page = 1;
      }

      if (this.query.company && this.query.company.id) {
        this.query.company_id = this.query.company.id;
      } else {
        this.query.company_id = null;
      }
      if (this.logedinUser.roles[0].name === "admin") {
        let roles = this.logedinUser.roles.map((role) => {
          return role.name;
        });
        if (roles.includes("supervisor")) {
          this.query.company_id = this.logedinUser.supervisor.company_id;
        } else {
          this.query.company_id = this.logedinUser.id;
        }
      }
      this.query.industry = this.query.selectedIndustry
        ? this.query.selectedIndustry.id
        : null;
      this.$store
        .dispatch("customer/getCustomers", this.query)
        .then((response) => {
          this.inactive_count = response.inactive_count;
          this.active_count = response.active_count;
          // reassigned the customers to the new array which has the customer_to_company.length > 0 in customer object
          let data = response.data.data;
          let customers = [];
          data.forEach((element) => {
            if (element.customer_to_company.length > 0) {
              customers.push(element);
            }
          });
          this.customers = customers;
          let cities = [];
          data.forEach((element) => {
            if (
              element.customer.city &&
              !cities.includes(element.customer.city)
            ) {
              cities.push(element.customer.city);
            }
          });
          this.cities = cities;
          this.total = response.data.total;
          this.from = response.data.from;
        });
    },
    getIndustries() {
      let query = {
        per_page: 1000,
      };
      this.$store.dispatch("industry/getIndustries", query).then((response) => {
        this.industries = response.data.data;
      });
    },
    async deleteUser(item) {
      await this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete the client.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            let del = await this.$store.dispatch("user/deleteUser", item);
            if (del.status_code == 200) {
              toast(
                "Deleted",
                "AlertTriangleIcon",
                "danger",
                "Client deleted !"
              );
              this.getCustomers();
            }
          }
        })
        .catch((err) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            err?.response?.data?.message || "Can't Delete! Please try again."
          );
          // An error occurred
        });
    },
    getIndustry(id) {
      let industry = this.industries.find((industry) => {
        return industry.id == id;
      });
      return industry ? industry.name : "";
    },
    suspendAlert(item) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to suspend the Client.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.suspendUser(item);
          }
        })
        .catch((err) => {
          console.log(err, "cancel");
          // An error occurred
        });
    },
    suspendUser(item) {
      // statusChange

      let data = {
        id: item,
        status: 1,
      };

      this.$store
        .dispatch("user/statusChange", data)
        .then((response) => {
          if (response.status_code === 200) {
            toast("Suspended", "CheckCircleIcon", "danger", "User Suspended");
            this.getCustomers();
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.message || "Error Suspending User"
          );
        });
    },
    activeUser(item) {
      // statusChange
      let data = {
        id: item,
        status: 0,
      };
      this.$store
        .dispatch("user/statusChange", data)
        .then((response) => {
          if (response.status_code === 200) {
            toast("Activated", "CheckCircleIcon", "success", "User Activated");
            this.getCustomers();
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.message || "Error Suspending User"
          );
        });
    },
  },
};
</script>

<style scoped>
.label-font {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-top: 10px;
}
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin: 0px 3px;
}

.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.has-border {
  border: 1px solid #7190ef;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 5px;
  color: #7190ef;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
